import * as React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import FlightIcon from '../icnos/FlightIcon';
import FlightLineIcon from '../icnos/FlightLineIcon';

type props = {
  index: number,
  pageNum: number,
  flights: any,
  spotName: string,
  duration: string,
  description: any,
  airportDist: number,
  depAirportIata: string,
  arrAirportIata: string,
  region: string
}

const CardComponent: React.FC<props> = (props) => {
  const detailPath = `detail/${props.index + (props.pageNum - 1) * 5 + 1}`
  return (
    <Card sx={{ display: 'flex', boxShadow: 4, mb: 3 }}>
      <Grid container sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }} >
        <Grid xs={12} md={8}>
          <Box sx={{ p: { xs: 0, sm: 2 } }}>
            <CardContent>
              {/* <Typography gutterBottom component="a" sx={{ fontWeight: '900' }} >
                {props.spotName}
              </Typography> */}
              {/* <Box component="a" href={detailPath} target='_blank'
                sx={{
                  display: "flex", mb: 2,
                  fontSize: "26px", fontWeight: '900',
                  color: "rgba(0, 0, 0, 0.87)",
                }} >
                {props.spotName}
              </Box> */}
              <Box component={Link} to={detailPath}
                sx={{
                  display: "flex", mb: 2,
                  fontSize: "26px", fontWeight: '900',
                  color: "rgba(0, 0, 0, 0.87)",
                }} >
                {props.spotName}
              </Box>
              {/* <Typography gutterBottom component="div" sx={{ mb: { xs: 2, sm: 3 } }}>
                {props.location}, {props.country}
              </Typography> */}
              <Box sx={{ mb: 3 }}>
                {/* <Typography variant="body1" color="text.secondary" mb={2}>
                  Conditions: Consistency, temperature, bottom,
                </Typography> */}
                <Typography variant="body1" >
                  {props.description.preview}
                </Typography>
              </Box>
              <Divider />


              {props.flights.details &&
                <Stack py={3} justifyContent="space-between" alignItems="center" sx={{ flexDirection: { xs: 'column', md: "row", lg: "column", xl: 'row' } }} >
                  <Stack direction="row" alignItems="center" sx={{ mb: { xs: 2, xl: 0 } }}>
                    <Box>
                      <Typography component="p" variant='body1' fontSize="24px">{props.flights.details[0].departureTime}</Typography>
                      <Typography component="p" variant='body1' fontWeight="bold" textAlign="right" >{props.flights.details[0].from}</Typography>
                    </Box>
                    <Box px={2}>
                      <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <Box textAlign="center" mr={1} sx={{ display: "flex", flexDirection: "column" }} >
                          <Typography component="p" variant='body2' mb="4px" lineHeight={1}>{props.flights.details[0].duration.replace(/:/g, "h ")}</Typography>
                          <Box component={FlightLineIcon} stopNum={props.flights.details[0].stops} />
                          <Typography component="p" variant='body2' mt="4px" lineHeight={1}>&nbsp;</Typography>
                        </Box>
                        <Box component={FlightIcon} />
                      </Box>
                    </Box>
                    <Box>
                      <Typography component="p" variant='body1' fontSize="24px" sx={{ ml: "-4px" }}>{props.flights.details[0].arrivalTime}</Typography>
                      <Typography component="p" variant='body1' fontWeight="bold">{props.flights.details[0].to}</Typography>
                    </Box>
                    {/* <Typography gutterBottom variant="body1" component="p" fontWeight='bold' mb={0}>
                    {props.depAirportIata} &lt; &gt; {props.arrAirportIata}
                  </Typography> */}
                    {/* <Typography gutterBottom variant="body1" component="p" mb={0}>
                    &nbsp;round trip flight
                  </Typography> */}
                  </Stack>
                  <Box sx={{ width: "2px", height: "20px", backgroundColor: "rgba(0, 0, 0, 0.12)", display: { xs: "none", xl: "block" } }} />
                  {
                    props.flights.details[1]&&
                    <Stack direction="row" alignItems="center" sx={{ mb: { xs: 2, xl: 0 } }}>
                    <Box>
                      <Typography component="p" variant='body1' fontSize="24px">{props.flights.details[1].departureTime}</Typography>
                      <Typography component="p" variant='body1' fontWeight="bold" textAlign="right" >{props.flights.details[1].from}</Typography>
                    </Box>
                    <Box px={2}>
                      <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <Box textAlign="center" mr={1} sx={{ display: "flex", flexDirection: "column" }} >
                          <Typography component="p" variant='body2' mb="4px" lineHeight={1}>{props.flights.details[1].duration.replace(/:/g, "h ")}</Typography>
                          <Box component={FlightLineIcon} stopNum={props.flights.details[1].stops} />
                          <Typography component="p" variant='body2' mt="4px" lineHeight={1}>&nbsp;</Typography>
                        </Box>
                        <Box component={FlightIcon} />
                      </Box>
                    </Box>
                    <Box>
                      <Typography component="p" variant='body1' fontSize="24px" sx={{ ml: "-4px" }}>{props.flights.details[1].arrivalTime}</Typography>
                      <Typography component="p" variant='body1' fontWeight="bold">{props.flights.details[1].to}</Typography>
                    </Box>
                  </Stack>
                  }
                </Stack>
              }

              <Divider sx={{ mb: 3 }} />
              {props.flights.details &&
                <Typography component="p" variant='body1' fontSize="22px" fontWeight="600" lineHeight={1}>${props.flights.price}</Typography>
              }
              <Typography component="p" variant='body1' fontSize="18px">Distance from airport: <span style={{fontSize: '22px', fontWeight:'600'}}>{props.airportDist}km</span></Typography>
            </CardContent>
          </Box>
        </Grid>
        <Grid xs={12} md={4} sx={{ height: { xs: "200px", sm: "300px", md: "100%" } }}>
          <Box component="img" sx={{ width: "100%", height: "100%", objectFit: "cover" }} src={`https://kitetripscanner.com/assets/images/minResSpots/${props.description.picture}`} alt="image" />
        </Grid>
      </Grid>
    </Card >
  );
}

export default CardComponent;
